/* FONTS */
@font-face {
  font-family: "ModernEra";
  src: url("../fonts/modernera/ModernEra-Regular.eot"); /* IE9 Compat Modes */
  src:
    url("../fonts/modernera/ModernEra-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/modernera/ModernEra-Regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/modernera/ModernEra-Regular.otf")
      format("otf"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html {
  font-size: 100%;
}

body {
  font-family: "ModernEra", Georgia;
  color: #333333;
}

img {
  height: auto;
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: all 250ms ease;
}

button {
  border: 0;
  cursor: pointer;
  transition: all 250ms ease;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
  box-shadow: 0 0 0.5rem 0.125rem #8568af;
}

ul {
  list-style-type: none;
  padding: 0;
}
